<template>
  <div style="width: 100%; height: 100%" ref="hygrometer"></div>
</template>
<script>
export default {
  mounted() {
    this.drawLiquidfill();
  },

  data() {
    return {};
  },
  props: {
    socData: {
      type: Number,
      default() {
        return 0;
      },
    },
    state: {
      type: String,
      default() {
        return "";
      },
    },
  },
  watch: {
    socData() {
      this.drawLiquidfill();
    },
    state() {
      this.drawLiquidfill();
    },
  },
  methods: {
    drawLiquidfill() {
      console.log(this.state);
      // 基于准备好的dom，初始化echarts实例
      let hygrometer = this.$echarts.init(this.$refs.hygrometer);
      // 使用指定的配置项和数据显示图表
      var value = this.socData * 0.01;
      // console.log(value, "0000000");
      let option = {
        series: [
          {
            type: "liquidFill", //配置echarts图类型
            radius: "60%",
            center: ["50%", "50%"],
            //  shape: 'roundRect',// 设置水球图类型（矩形[rect]，菱形[diamond]，三角形[triangle]，水滴状[pin],箭头[arrow]...） 默认为圆形
            data: [value, value], //设置波浪的值
            waveAnimation: false, //静止的波浪
            backgroundStyle: {
              borderWidth: 1,
              color: "transparent", //水球图内部背景色
            },
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#5acef2",
              },
            },
            amplitude: 15, // 振幅
            color: [
              //波浪颜色
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    // color: "rgba(6, 187, 112, 1)", //下
                    color: "rgba(0, 83, 215,0.3)",
                  },
                  {
                    offset: 0,
                    color: "rgba(134, 187, 255,0.3)",
                    // color: "rgba(11, 201, 199, 1)",
                  },
                  // {
                  //   offset: 1,
                  //   color: "rgba(6, 187, 112, 0.3)", //下
                  // },
                  // {
                  //   offset: 0,
                  //   color: "rgba(11, 201, 199, 0.3)",
                  // },
                ],
                globalCoord: false,
              },
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    // color: "rgba(6, 187, 112, 1)", //下
                    color: "rgba(0, 83, 215,1)",
                  },
                  {
                    offset: 0,
                    color: "rgba(134, 187, 255,1)",
                    // color: "rgba(11, 201, 199, 1)",
                  },
                ],
                globalCoord: false,
              },
            ],
            label: {
              normal: {
                // color: '#FC7272',
                color: "#fff",
                textStyle: {
                  fontSize: 18,
                },
                formatter: (params) => {
                  //console.log(params)
                  let newValue = params.value * 100;
                  return newValue.toFixed(0) + "%\nSOC";
                },
              },
            },
          },
        ],
      };
      if (this.state == "运行") {
        option.series[0].waveAnimation = true;
      }
      hygrometer.setOption(option);
      hygrometer.resize();
      window.addEventListener("resize", function () {
        hygrometer.resize();
      });
    },
  },
};
</script>
