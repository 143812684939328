import { http } from "../utils/http2";

export const consistencySelectAll = (data) =>
  http.post("consistency/selectAll", data);
export const sysTopInfo = (data) => http.post("sys/selectByInfo", data);
export const sysTopInfo1 = (data) => http.post("sys2/select2ByInfo", data);
export const queryTotalModule = (data) =>
  http.post("module/queryTotalModule", data);
export const sysPowerInfo = (data) => http.post("sys/queryBySysInfo", data);
export const socInfo = (data) => http.post("bams/selectTfHour", data);
export const searchSysBamsInfo = (data) =>
  http.post("sysbams/searchSysBamsInfo", data);
export const bamsSelectByDate = (data) => http.post("bams/selectByDate", data); //BAMS_info 根据日期 SOC、电压、电流 参数siteid slaveID 日期
export const queryAttenuation = (data) =>
  http.get("full/queryAttenuation", data);

export const getSiteRecommend = (data) =>
  http.get("/alarm/getSiteRecommend", data);
