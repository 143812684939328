<template>
  <div class="device-content">
    <div class="top">
      <el-row :gutter="5">
        <el-col :span="6">
          <div class="device-box mode-box">
            <div class="title">
              <lable-name name="设备信息"></lable-name>
            </div>
            <div class="device-top flex space-around items-middle">
              <el-row style="width: 40%">
                <template v-for="(v, i) in deviceBase1">
                  <el-col
                    :span="24"
                    class="device"
                    :key="i"
                    v-if="v.unit == ''"
                  >
                    <icon-title
                      :imgSrc="v.src"
                      :showType="1"
                      :title="v.title"
                      :value="v.value"
                    ></icon-title>
                  </el-col>
                </template>
              </el-row>
              <div class="soc-info">
                <wapper :socData="soc"></wapper>
              </div>
            </div>
            <el-row :gutter="0">
              <el-col :span="12" v-for="(v, i) in deviceBase1" :key="i">
                <div class="device" :key="i" v-if="v.unit != ''">
                  <icon-title
                    :imgSrc="v.src"
                    :title="v.title"
                    :value="v.value"
                  ></icon-title></div
              ></el-col>
            </el-row>

            <!-- <div class="device-bottom flex items-middle">
              <template v-for="(v, i) in deviceBase1">
                
              </template>
            </div> -->
          </div>
        </el-col>
        <el-col :span="18">
          <div class="model-box mode-box">
            <div class="title">
              <lable-name name="模块信息"></lable-name>
            </div>
            <div class="model-content flex space-around items-middle">
              <div class="model" v-for="(v, i) in modelInfo" :key="i">
                <model-box :info="v"></model-box>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="middle">
      <el-row :gutter="5">
        <el-col :span="12"
          ><div class="power-box mode-box">
            <div class="title-box flex space-between items-middle">
              <div class="title">
                <lable-name name="功率分析"></lable-name>
              </div>
              <div class="power-time-form">
                <el-form :model="search">
                  <el-date-picker
                    v-model="search.date"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    @change="getPowerDate"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form>
              </div>
            </div>
            <div class="power-chart">
              <b-line
                :x-data="chartsData.xData"
                :gridActivePower="chartsData.gridPowerData"
                :loadActivePower="chartsData.loadPowerData"
                :socArr="chartsData.socData"
                :title1="$t('echartsData.activePowerOfGrid')"
                :title2="$t('echartsData.loadActivePower')"
                title3="SOC"
                :data3YAxisIndex="1"
                y1name="SOC"
                :is-fresh="isFresh"
              />
            </div></div
        ></el-col>
        <el-col :span="12"
          ><div class="power-box mode-box">
            <div class="title-box flex space-between items-middle">
              <div class="title">
                <lable-name name="设备效率分析"></lable-name>
              </div>
              <div class="power-time-form">
                <el-form :model="search">
                  <el-select
                    v-model="tValue"
                    filterable
                    allow-create
                    default-first-option
                    @change="getTEff"
                  >
                    <el-option
                      v-for="item in tOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form>
              </div>
            </div>
            <div class="power-chart">
              <eff-line :xData="attenuation" :is-fresh="isFresh"></eff-line>
            </div></div
        ></el-col>
      </el-row>
    </div>
    <div class="bottom">
      <el-row :gutter="5">
        <el-col :span="12"
          ><div class="power-box mode-box">
            <div class="title-box flex space-between items-middle">
              <div class="title">
                <lable-name name="充放电极差曲线"></lable-name>
              </div>
              <!-- <div class="power-time-form">
                <el-form :model="search">
                  <el-date-picker
                    v-model="search.date"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    :picker-options="pickerOptions"
                    @change="getPowerDate"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form>
              </div> -->
            </div>
            <div class="bat-chart">
              <template v-if="isShowVol">
                <bat-line
                  :xData="voltageRangeTime"
                  :yData="voltageRange"
                ></bat-line>
              </template>
              <template v-else>
                <no-data></no-data>
              </template>
            </div></div
        ></el-col>
        <el-col :span="12">
          <div class="power-box mode-box">
            <div class="title">
              <lable-name name="电池一致性分析"></lable-name>
            </div>
            <div class="bat-chart">
              <template v-if="isShowVol">
                <bat-chart
                  :xData="combinedArray"
                  :highMax="highMax"
                  :highMin="highMin"
                  :lowMax="lowMax"
                  :lowMin="lowMin"
                ></bat-chart>
              </template>
              <template v-else>
                <no-data></no-data>
              </template>
            </div></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  bamsSelectByDate,
  consistencySelectAll,
  queryAttenuation,
  queryTotalModule,
  searchSysBamsInfo,
  socInfo,
  sysPowerInfo,
  sysTopInfo,
  sysTopInfo1,
} from "../../../api/device";
import iconTitle from "../../../components/iconTitle.vue";
import LableName from "../../../components/LableName.vue";
import bLine from "./components/Line.vue";
import effLine from "./components/effLine.vue";
import Wapper from "../components/wapper.vue";
import ModelBox from "./components/modelBox.vue";
import batLine from "./components/batLine.vue";
import batChart from "./components/batChart.vue";
import NoData from "../../../components/noData.vue";
export default {
  components: {
    iconTitle,
    LableName,
    ModelBox,
    Wapper,
    bLine,
    effLine,
    batLine,
    batChart,
    NoData,
  },
  props: {
    isFresh: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  data() {
    return {
      deviceInfo: {
        siteId: "",
        slaveId: "",
      },
      soc: 60,
      deviceBase1: [
        {
          title: "设备状态",
          value: "运行",
          unit: "",
          src: require("@/assets/imgs/u151.svg"),
        },
        {
          title: "运行模式",
          value: "P/Q",
          unit: "",
          src: require("@/assets/imgs/u166.svg"),
        },
        {
          title: "空调状态",
          value: "开机",
          unit: "",
          src: require("@/assets/imgs/u176.svg"),
        },
        {
          title: "累计充电量",
          value: 500,
          unit: "kWh",
          src: require("@/assets/imgs/u171.svg"),
        },
        {
          title: "累计放电量",
          value: 500,
          unit: "kWh",
          src: require("@/assets/imgs/u161.svg"),
        },
      ],
      deviceBase2: {
        title: "累计放电量",
        value: 500,
        unit: "kWh",
        src: require("@/assets/imgs/u161.svg"),
      },
      modelInfo: [
        {
          name: "BMS",
          imgSrc: require("@/assets/imgs/bat7.png"),
          key: [
            {
              name: "",
              value: "",
              unit: "",
            },
            {
              name: "SOC",
              value: 60,
              unit: "%",
            },
            {
              name: "电压",
              value: 60,
              unit: "V",
            },
            {
              name: "电流",
              value: 60,
              unit: "A",
            },
          ],
        },
        {
          name: "PCS",
          imgSrc: require("@/assets/imgs/pcs7.png"),
          key: [
            {
              name: "",
              value: "",
              unit: "",
            },
            {
              name: "交流有功功率",
              value: 60,
              unit: "kW",
            },
            {
              name: "交流A相电压",
              value: 60,
              unit: "V",
            },
            {
              name: "交流电流",
              value: 60,
              unit: "A",
            },
          ],
        },
        // {
        //   name: "光伏",
        //   imgSrc: require("@/assets/imgs/mppt7.png"),
        //   key: [
        //     {
        //       name: "SOC",
        //       value: 60,
        //       unit: "%",
        //     },
        //     {
        //       name: "电压",
        //       value: 60,
        //       unit: "V",
        //     },
        //     {
        //       name: "电流",
        //       value: 60,
        //       unit: "A",
        //     },
        //   ],
        // },
      ],
      chartsData: {
        socData: [],
        gridPowerData: [],
        xData: [],
        loadPowerData: [],
      },
      search: {
        date: "",
      },
      attenuation: [],
      tValue: "",
      tOption: [
        {
          label: "日",
          value: 1,
        },
        {
          label: "周",
          value: 7,
        },
      ],
      voltageRange: {},
      voltageRangeTime: [],
      highVol: [],
      lowVol: [],
      isShowVol: false,
      combinedArray: [],
      highMax: 0,
      highMin: 0,
      lowMax: 0,
      lowMin: 0,
    };
  },
  watch: {
    isFresh(newValue, oldValue) {
      console.log(newValue, "isFresh");
      this.isFresh = newValue;
    },
  },
  mounted() {
    this.deviceInfo.siteId = this.$route.query.siteId;
    this.deviceInfo.slaveId = this.$route.query.slaveId;
    this.getData();
    this.getSysPowerInfo();
    this.getEff();
    this.getBms();
  },
  methods: {
    getData() {
      sysTopInfo(this.deviceInfo).then((res) => {
        let data = res.data.data[0];
        this.deviceBase1[3].value = data.cumulativeCharge;
        this.deviceBase1[4].value = data.cumulativeDischarge;
      });
      sysTopInfo1(this.deviceInfo).then((res) => {
        let data = res.data.data[0];
        this.deviceBase1[1].value = data.workMode;
        this.deviceBase1[2].value = data.airStatus;
        this.deviceBase1[0].value = data.workCondition;
        this.soc = data.sysSOC;
      });
      queryTotalModule(this.deviceInfo).then((res) => {
        let data = res.data.data;
        this.modelInfo[0].key[0].value = data["汇总bms状态"];
        this.modelInfo[0].key[1].value = data["汇总bms电池组总soc"];
        this.modelInfo[0].key[2].value = data["汇总bms电池组总电压"];
        this.modelInfo[0].key[3].value = data["汇总bms电池组总电流"];
        this.modelInfo[1].key[0].value = data["汇总pcs状态"];
        this.modelInfo[1].key[1].value = data["汇总pcs交流有功功率"];
        this.modelInfo[1].key[2].value = data["汇总pcs交流a相电压"];
        this.modelInfo[1].key[3].value = data["汇总pcs交流电流"];
        // this.deviceBase1[1].value = data.workMode;
        // this.deviceBase1[2].value = data.airStatus;
        // this.deviceBase1[0].value = data.workCondition;
        // this.soc = data.sysSOC;
      });
    },
    async getSysPowerInfo() {
      if (this.search.date == "") {
        let sysData = await sysPowerInfo(this.deviceInfo);
        let socData = await socInfo(this.deviceInfo);
        let currTime1 = [];
        let currTime2 = [];
        this.chartsData.socData = [];
        this.chartsData.loadPowerData = [];
        this.chartsData.gridPowerData = [];
        this.chartsData.xData = [];
        if (socData.data.data.length) {
          sysData.data.data.forEach((v) => {
            this.chartsData.loadPowerData.push([v.currTime, v.loadActivePower]);
            this.chartsData.gridPowerData.push([v.currTime, v.gridActivePower]);
            currTime1.push(v.currTime);
          });
          socData.data.data.forEach((v) => {
            this.chartsData.socData.push([v.currTime, v.stackSOC]);
            currTime1.push(v.currTime);
          });
          this.socNum = parseFloat(
            socData.data.data[socData.data.data.length - 1].stackSOC / 100
          );
        } else {
          sysData.data.data.forEach((v) => {
            this.chartsData.loadPowerData.push([v.currTime, v.loadActivePower]);
            this.chartsData.gridPowerData.push([v.currTime, v.gridActivePower]);
            this.chartsData.socData.push([v.currTime, v.sysSOC]);
            currTime1.push(v.currTime);
          });
          this.socNum = parseFloat(
            sysData.data.data[sysData.data.data.length - 1].sysSOC / 100
          );
        }
        currTime2 = Array.from(new Set(currTime1));
        this.chartsData.xData = currTime2.sort((a, b) => {
          return new Date(a) - new Date(b);
        });
      }
    },
    async getPowerDate() {
      let obj = {
        siteId: this.deviceInfo.siteId,
        slaveId: this.deviceInfo.slaveId,
        currTime: this.search.date + "%",
      };
      let sysData = await searchSysBamsInfo(obj);
      let socData = await bamsSelectByDate(obj);
      this.chartsData.socData = [];
      this.chartsData.loadPowerData = [];
      this.chartsData.gridPowerData = [];
      this.chartsData.xData = [];
      let currTime1 = [];
      let currTime2 = [];
      if (socData.data.data.length) {
        sysData.data.data.forEach((v) => {
          this.chartsData.loadPowerData.push([v.currTime, v.loadActivePower]);
          this.chartsData.gridPowerData.push([v.currTime, v.gridActivePower]);
          currTime1.push(v.currTime);
        });
        socData.data.data.forEach((v) => {
          this.chartsData.socData.push([v.currTime, v.stackSOC]);
          currTime1.push(v.currTime);
        });
      } else {
        sysData.data.data.forEach((v) => {
          this.chartsData.loadPowerData.push([v.currTime, v.loadActivePower]);
          this.chartsData.gridPowerData.push([v.currTime, v.gridActivePower]);
          this.chartsData.socData.push([v.currTime, v.sysSOC]);
          currTime1.push(v.currTime);
        });
      }

      currTime2 = Array.from(new Set(currTime1));
      this.chartsData.xData = currTime2.sort((a, b) => {
        return new Date(a) - new Date(b);
      });
    },
    getEff() {
      queryAttenuation({
        siteId: this.deviceInfo.siteId,
        slaveId: this.deviceInfo.slaveId,
        t: 1,
      }).then((res) => {
        this.attenuation = res.data.data;
      });
    },
    getTEff() {
      queryAttenuation({
        siteId: this.deviceInfo.siteId,
        slaveId: this.deviceInfo.slaveId,
        t: this.tValue,
      }).then((res) => {
        this.attenuation = res.data.data;
      });
    },
    getBms() {
      let obj1 = {
        bmsId: 1,
        siteId: this.deviceInfo.siteId,
        slaveId: this.deviceInfo.slaveId,
      };
      consistencySelectAll(obj1).then((res) => {
        if (
          res.data.data.highTensionList.length > 0 &&
          res.data.data.lowTensionList.length > 0
        ) {
          this.isShowVol = true;

          let arr1 = res.data.data.highTensionList[0].voltage.split(",");
          this.highVol = arr1
            .map((str) => parseInt(str))
            .filter((num) => num !== 0);
          let arr2 = res.data.data.lowTensionList[0].voltage.split(",");
          this.lowVol = arr2
            .map((str) => parseInt(str))
            .filter((num) => num !== 0);

          this.getArr(this.highVol, this.lowVol);
          this.voltageRange = { highTensionList: [], lowTensionList: [] };
          let currTime1 = [];
          res.data.data.highTensionList.forEach((v, i) => {
            let time = v.createTime.substring(0, 10);
            this.voltageRange.highTensionList.push([time, v.range]);
            currTime1.push(time);
          });
          res.data.data.lowTensionList.forEach((v, i) => {
            let time = v.createTime.substring(0, 10);
            this.voltageRange.lowTensionList.push([time, v.range]);
            currTime1.push(time);
          });
          let currTime2 = Array.from(new Set(currTime1));
          this.voltageRangeTime = currTime2;
          this.voltageRangeTime = currTime2.sort((a, b) => {
            return new Date(a) - new Date(b);
          });
        }
      });
    },
    getArr(array1, array2) {
      for (let i = 0; i < array1.length; i++) {
        this.combinedArray.push([
          (array1[i] / 1000).toFixed(3),
          (array2[i] / 1000).toFixed(3),
          i + 1,
        ]);
      }
      let avg1 = this.calculateAverage(this.highVol);
      let avg2 = this.calculateAverage(this.lowVol);
      this.highMax = (avg1 + 0.1).toFixed(3);
      this.highMin = (avg1 - 0.1).toFixed(3);
      this.lowMax = (avg2 + 0.1).toFixed(3);
      this.lowMin = (avg2 - 0.1).toFixed(3);
    },
    calculateAverage(array) {
      var sum = 0;
      for (var i = 0; i < array.length; i++) {
        sum += array[i] / 1000;
      }
      var average = sum / array.length;
      return average;
    },
  },
};
</script>

<style lang="scss" scoped>
.device-content {
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: auto;
  overflow-y: scroll;
  background: #002140;
}
.model-content {
  height: calc(100% - 40px);
  margin: 0 20px;
}
.middle {
  margin: 5px 0;
}
.model {
  height: 100%;
}
.mode-box {
  background: #222e4a;
  height: 100%;
  //margin: 20px 0;
}
.device {
  padding: 10px 10px;
}
.soc-info {
  width: 60%;
  height: 220px;
}
.model-box {
  height: 325px;
}
.power-chart {
  height: 250px;
  padding: 10px;
}
.power-time-form {
  margin: 20px 20px 0 0;
}
.bat-chart {
  height: 400px;
}
</style>
