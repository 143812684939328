<template>
  <div class="line-container">
    <div :id="lineDiv" style="width: 90%; height: 400px"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/scatter");
import I18n from "@/lang";
export default {
  name: "BLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    yData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    xData() {
      this.drawWeatherLine();
    },
    yData() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    let that = this;
    window.addEventListener("resize", function () {
      console.log("resize");
      // that.drawWeatherLine();
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    let that = this;
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));

      let highTensionData = this.yData.highTensionList;
      let lowTensionData = this.yData.lowTensionList;
      highTensionData.sort(function (a, b) {
        let dateA = new Date(a[0]);
        let dateB = new Date(b[0]);
        return dateA - dateB;
      });
      lowTensionData.sort(function (a, b) {
        let dateA = new Date(a[0]);
        let dateB = new Date(b[0]);
        return dateA - dateB;
      });
      //多线段（polyline=true），如图左侧连续一段：
      let datacoords = [
        {
          coords: highTensionData,
        },
      ];
      let datacoords2 = [
        {
          coords: lowTensionData,
        },
      ];
      console.log(datacoords, datacoords2, this.yData);

      let option = {
        animation: true, //控制动画示否开启
        animationDuration: 3000,
        animationEasing: "bounceOut", //缓动动画
        animationThreshold: 8, //动画元素的阈值
        backgroundColor: "transparent", // 给echarts图设置背景色
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.5)",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "rgba(0,0,0,.5)",
            },
          },
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        legend: {
          data: ["高压保护极差", "低压保护极差"],
          textStyle: {
            fontSize: 15,
            color: "#fff",
          },
          top: "15",
        },
        grid: {
          left: "3%", //图表距边框的距离
          right: "3%",
          top: "15%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            nameGap: 3,
            nameTextStyle: {
              color: "rgba(255,255,255,.8)",
              fontSize: 12,
            },
            type: "category",
            data: this.xData,
            boundaryGap: false, //从0开始
            axisLine: {
              onZero: true,
              rotate: 30, //坐标轴内容过长旋转
              interval: 1,
              lineStyle: {
                color: "#636E7C",
              },
            },
            axisLabel: {
              color: "rgba(255,255,255,.8)", //坐标的字体颜色
              fontSize: 12,
            },
            axisTick: {
              //坐标轴刻度颜色  x和y不交叉
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: "mV",
            min: 0,
            max: function (value) {
              return Math.ceil(value.max / 5) * 5;
            },
            splitNumber: 5,
            type: "value",
            nameTextStyle: {
              color: "rgba(255,255,255,.89)",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.25)",
                type: "dashed",
              },
            },
            axisTick: {
              //坐标轴刻度颜色
              show: false,
            },
            axisLine: {
              //坐标轴线颜色
              show: true,
              lineStyle: {
                color: "#636E7C",
              },
            },
            axisLabel: {
              color: "rgba(255,255,255,.8)", //坐标的字体颜色
              fontSize: 12,
            },
          },
        ],
        series: [
          {
            name: "高压保护极差",
            type: "line",
            smooth: false,
            lineStyle: {
              color: "#FE8800",
              width: 1.5,
              type: "dashed",
              shadowOffsetX: 0, // 折线的X偏移
              shadowOffsetY: 10, // 折线的Y偏移
              shadowBlur: 4, // 折线模糊
              shadowColor: "rgba(255, 255, 255, 0.8)", //设置折线阴影颜色
            },
            showSymbol: true, //是否默认展示圆点
            symbol: "circle", // 默认是空心圆（中间是白色的）
            symbolSize: 7,
            itemStyle: {
              color: "#021E47", //实圆的背景色
              borderWidth: 1,
              borderColor: "#FE8800",
            },
            // areaStyle: {
            //   color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            //     {
            //       offset: 1,
            //       color: "rgba(220,120,40,0.8)",
            //     },
            //     {
            //       offset: 0.74,
            //       color: "rgba(220,120,40,0.5)",
            //     },
            //     {
            //       offset: 0,
            //       color: "rgba(220,120,40,0)",
            //     },
            //   ]),
            // },
            emphasis: {
              focus: "series",
            },
            data: highTensionData,
          },
          {
            showSymbol: false,
            name: I18n.t("bmsInfo.HighVoltageProtectionVoltageRange"),
            type: "lines",
            polyline: true,
            smooth: false,
            coordinateSystem: "cartesian2d",
            zlevel: 1,
            effect: {
              show: true,
              smooth: true,
              period: 6,
              symbolSize: 4,
            },
            lineStyle: {
              color: "#fff",
              width: 1,
              opacity: 0,
              curveness: 0,
              cap: "round",
            },
            data: datacoords,
          },
          {
            name: "低压保护极差",
            type: "line",
            smooth: false,
            lineStyle: {
              color: "#0af1f4",
              width: 1.5,
              shadowOffsetX: 0, // 折线的X偏移
              shadowOffsetY: 10, // 折线的Y偏移
              shadowBlur: 4, // 折线模糊
              shadowColor: "rgba(255, 255, 255, 0.8)", //设置折线阴影颜色
            },
            showSymbol: true, //是否默认展示圆点
            symbol: "circle", // 默认是空心圆（中间是白色的）
            symbolSize: 7,
            itemStyle: {
              color: "#021E47", //实圆的背景色
              borderWidth: 1,
              borderColor: "#0af1f4",
            },
            // areaStyle: {
            //   color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            //     {
            //       offset: 1,
            //       color: "rgba(10, 241, 244,0.8)",
            //     },
            //     {
            //       offset: 0.74,
            //       color: "rgba(10, 241, 244,0.5)",
            //     },
            //     {
            //       offset: 0,
            //       color: "rgba(10, 241, 244,0)",
            //     },
            //   ]),
            // },
            emphasis: {
              focus: "series",
            },
            data: lowTensionData,
          },
          {
            showSymbol: false,
            name: I18n.t("bmsInfo.LowVoltageProtectionVoltageRange"),
            type: "lines",
            polyline: true,
            smooth: false,
            coordinateSystem: "cartesian2d",
            zlevel: 1,
            effect: {
              show: true,
              smooth: true,
              period: 6,
              symbolSize: 4,
            },
            lineStyle: {
              color: "#fff",
              width: 1,
              opacity: 0,
              curveness: 0,
              cap: "round",
            },
            data: datacoords2,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.line-container {
    height: 100%;
    width: 100%;
    display: flex
    justify-content: center
}
</style>
