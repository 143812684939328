<template>
  <div class="icon-title-box flex">
    <div class="icon">
      <img :src="imgSrc" style="width: 27px; padding: 10px" />
    </div>
    <div class="title">
      <div class="name">
        {{ title }}
      </div>
      <div class="val-unit" v-if="showType == 0">
        <span class="val">{{ value | num }}</span>
        <span class="unit">{{ unit }}</span>
      </div>
      <div class="val-unit" v-else-if="showType == 1">
        <span class="val">{{ value }}</span>
        <span class="unit">{{ unit }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      default() {
        return 0 | "0";
      },
    },
    unit: {
      type: String,
      default: "",
    },
    showType: {
      type: [Number, String],
      default() {
        return 0 | "0";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-title-box {
  height: 50px;

  .icon {
    width: 46px;
    height: 46px;
    border: 1px solid #31a7ff;
    border-radius: 50%;
  }
  .title {
    padding-left: 10px;
    .name {
      height: 25px;
      line-height: 25px;
      font-size: 12px;
      color: #43cdf8;
    }
    .val-unit {
      .val {
        font-size: 22px;
        color: #fff;
        font-weight: 700;
      }
      .unit {
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
</style>
