<template>
  <div class="line-container">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/scatter");
import i18n from "@/lang";
export default {
  name: "BLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    yData: {
      type: Object,
      default() {
        return {};
      },
    },
    isFresh: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    xData() {
      this.drawWeatherLine();
    },
    yData() {
      this.drawWeatherLine();
    },
    isFresh() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    window.addEventListener("resize", () => {
      if (document.getElementById(this.lineDiv)) {
        this.drawWeatherLine();
      }
    });
  },
  beforeDestroy() {
    let that = this;
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.getInstanceByDom(
        document.getElementById(this.lineDiv)
      );
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById(this.lineDiv));
      }

      // 计算最大值和最小值
      var attenuationValues = this.xData.map(function (item) {
        return parseFloat(item.capacity);
      });
      var minValue = Math.min(...attenuationValues);
      var maxValue = Math.max(...attenuationValues);
      var curr = this.xData[this.xData.length - 1].currTime.substring(0, 10);
      console.log(minValue, maxValue, "---------", maxValue - minValue);
      let option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          // padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
        },

        grid: {
          left: 50,
          right: 50,
          bottom: 20,
          top: 50,
        },
        legend: {
          // data: legend, //注意：图例的名字必须跟下面series数组里面的name一致
          itemHeight: 9, //改变圆圈大小
          icon: "circle",
          textStyle: {
            color: "#fff", // 图例文字颜色
          },
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData.map((item) => item.currTime.substring(0, 10)),
          nameTextStyle: {
            color: "#ACB0BB",
            fontSize: 16,
            fontWeight: 200,
            padding: [0, 0, 20, 50], //调节显示位置(与y轴label对应关系)  上、右、下、左
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          // x轴字体设置
          axisLabel: {
            show: true,
            fontSize: 12,
            color: "#ACB0BB",
          },
        },
        yAxis: [
          {
            name: "kWh",
            type: "value",
            splitLine: {
              show: false,
            },
            // y轴字体设置
            axisLabel: {
              show: true,
              // formatter: '{value} %',
              fontSize: 12,
              color: "#fff",
            },
          },
          {
            name: "%",
            type: "value",

            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.25)",
                type: "dashed",
              },
            },
            // y轴字体设置
            axisLabel: {
              show: true,

              fontSize: 12,
              color: "#fff",
            },
          },
        ],
        series: [
          // {
          //   smooth: true,
          //   name: i18n.t("deviceDetail.cycleCount"),
          //   type: "line",
          //   yAxisIndex: 1,
          //   data: this.xData.map((item) => item.cycleCount),
          //   itemStyle: {
          //     //线条的渐变

          //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //       {
          //         offset: 1,
          //         color: "#47b72b", // 0% 处的颜色
          //       },
          //       {
          //         offset: 0,
          //         color: "#b4e2a9", // 100% 处的颜色
          //       },
          //     ]), //背景渐变色
          //   },
          // },
          {
            smooth: true,
            name: "充电量",
            type: "line",
            stack: "总量1",
            // yAxisIndex: 1,
            data: this.xData.map((item) => item.capacity),
            itemStyle: {
              //线条的渐变

              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "#00B7FF", // 0% 处的颜色
                },
                {
                  offset: 0,
                  color: "#A890FE", // 100% 处的颜色
                },
              ]), //背景渐变色
            },
            markPoint: {
              //图表标注
              data: [
                {
                  type: "max",
                  name: "最大值",
                  itemStyle: {
                    //自定义标注的颜色
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      {
                        offset: 0,
                        // color: "#0066ff",
                        color: "#01f7f8",
                      },
                      {
                        offset: 1,
                        // color: "#01f7f8",
                        color: "#0066ff",
                      },
                    ]), //线条渐变色
                  },
                },
                {
                  type: "min",
                  name: "最小值",
                  itemStyle: {
                    //自定义标注的颜色
                    color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      {
                        offset: 0,
                        // color: "#0066ff",
                        color: "#01f7f8",
                      },
                      {
                        offset: 1,
                        // color: "#01f7f8",
                        color: "#0066ff",
                      },
                    ]), //线条渐变色
                  },
                },
              ],

              label: {
                position: "insideTop", //标注文字位置
                distance: 3, //距离图形元素的距离
                textStyle: {
                  color: "#fff",
                  fontSize: "12px",
                },
              },
              symbolSize: [40, 32],
              symbolOffset: ["26%", "-50%"], //图标偏移
              animation: true,
              // symbol: 'path://m 0,0 h 48 v 20 h -30 l -6,10 l -6,-10 h -6 z', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', path://m 0,0 h 48 v 20 h -30 l -6,10 l -6,-10 h -6 z,  path://m 0,0 h 48 v 20 h -34 l -6,10 l -6,-10 h -2 z
              // symbol:
              //   "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGcAAAA4CAYAAADgmebbAAAABHNCSVQICAgIfAhkiAAABCVJREFUeF7tnEtsVFUYx8859zH30WlnihDT6VBKm+nUxJjIhrDB6JqNRFSI0UBYII9Ewag7N4aNkAjTEjU+YkKTJhp27oyJiSEujBuRaQWZlocR294LdLjv+zl3SAeoRSalYb5Jv1meOee7//v/nX/u2ZyPsyV+/sfFTZEKRRnk3oiFMTAps9Q8Glu+A5xFtsRkEUJ4Jea8bL5Z/mVxNX7vAJwY7PUVZafn8wlXVaaDWJ2xPbm6fAm08v8cyKRCUwVvrcG8vMRYIZZgzNg3eXVhTQPOjeO93YrSudvyxbjN0wTkMe+rrPA7OqXbr8zOVz/re3/aSh5fh5MkpsqUV6fCzOePWRM9bpEDfercHhPYaX5w4lodjlcafnfGk8YoMa3fK1nVTXdz5yVtf/kj7o8WNwVRnK8Ea35svTRSkDiQV+2tmsKm+Hyp8Fog6b9dc4wpsgaHA+vM6oaOyC9yb+Sp92aYeZpOZTjAJCr02E3ndPdlHowUj0x62S/wSCMliQOFlLWbO6PFD/90s8fIElwObNSswwQHF5OGGoKDFEwii+AQHMQOIJZGySE4iB1ALI2SQ3AQO4BYGiWH4CB2ALE0Sg7BQewAYmmUHIKD2AHE0ig5BAexA4ilUXIIDmIHEEuj5BAcxA4glkbJITiIHUAsjZJDcBA7gFgaJYfgIHYAsTRKDsFB7ABiaZQcgoPYAcTS6smh+zk4CQ1oc3voZhtCNo2bbd7I4K4ZMM7bvllBqHNVSlonWf06hyKv1m5T19oR5Ctuhm5TI9kK/frsVj2WL9X7ENweHXrH9jvGrVidR6Jv1cqo9SDorPUi2F7rQ3DsDpxThRyLpJ2XfOrg0epd0a/aeyFgX5tvnf+r0fvGPro+q3aae23Qxi1fu9Vqkavt+UliulRvx9//OJ/2f1Cxk/e/v2vUyaEej7NdXIg/HI9Nz0XarCNwgNLFLcWJ08GjQktFVdmTzPBR66zE+uRU1q04TyiC5yUZBiKXjyWJWah9H5yFwWpp8FlZyEUGvBcgBhCt77cmAG64ETt3JcycXa4x61PWcyrjw7X+ZsZya6zUuqTfGgfBIwaXIQrL5qELvy6uvSSclRKw0nWcUuEF4GL4um9+WwXVa7Z+skOf1JztDOBn4+DET82ua/W8toJTP7yMDOQ5U98IQvj+cpQtP8zAHtl62pD5Zp/7X6b3Xbz+sPmY/m87OAvmeaWhHQFI6lTQ9d2DDN2o3dwm4uBm6sDkGUymN6ulbeEkL+ifKDwTydI221XPzDGj8SFdo8znukTwogDxTerA7+eaNQPbvLaGk5gJn/QYXtz1OgM2fdHNnN2gW1ukmOVSUfgVP3Sh6e8SNjD/OUpjFNisJufU0PNRyPokwSr6/okfml2HeV7bJ+dec+F4IcffvttdFrPxzWj7F7l7EQD2ZrgfAAAAAElFTkSuQmCC",
              symbol:
                "path://M736 101.3H288c-123.2 0-224 100.8-224 224v149.3c0 123.2 100.8 224 224 224h37.3v224l256-224H736c123.2 0 224-100.8 224-224V325.3c0-123.2-100.8-224-224-224z",
            },
            markLine: {
              data: [
                {
                  name: "",
                  // 支持 'average', 'min', 'max'
                  type: "min",
                  // lineStyle: { color: "#FF7804" },
                  label: {
                    show: false,
                    color: "#04b6ff",
                    formatter: (params) => {
                      return "Min:" + params.data.value;
                    },
                    fontSize: 10,
                  },
                },
                {
                  name: "",
                  // 支持 'average', 'min', 'max'
                  type: "max",
                  label: {
                    show: false,
                    color: "#04b6ff",
                    formatter: (params) => {
                      return "Max:" + params.data.value;
                    },
                    fontSize: 10,
                  },
                },
                // {
                //   name: "Y 轴值为 100 的水平线",
                //   yAxis: 100,
                // },
                // [
                //   {
                //     // 起点和终点的项会共用一个 name
                //     name: "最小值到最大值",
                //     type: "min",
                //   },
                //   {
                //     type: "max",
                //   },
                // ],
                [
                  {
                    name: (maxValue - minValue).toFixed(2) + " kWh",
                    coord: [curr, maxValue],
                    // lineStyle: { color: "#FF7804" },
                    label: {
                      color: "#04b6ff",
                      fontSize: 12,
                    },
                    // label: {
                    //   color: "#FF7804",
                    //   formatter: "异常(25dB)",
                    //   fontSize: 10,
                    // },
                  },
                  {
                    coord: [curr, minValue],
                  },
                ],
                // [
                //   {
                //     // 固定起点的 x 像素位置，用于模拟一条指向最大值的水平线
                //     type: "min",
                //   },
                //   {
                //     type: "max",
                //   },
                // ],
              ],
            },
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.line-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
