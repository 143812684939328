<template>
  <div class="line-container">
    <div :id="lineDiv" style="width: 400px; height: 400px"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/scatter");
import I18n from "@/lang";
export default {
  name: "BLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    totalBatteryVoltage: {
      type: Array,
      default() {
        return [];
      },
    },
    loadActivePower: {
      type: Array,
      default() {
        return [];
      },
    },
    highMax: {
      type: [Number, String],
      default() {
        return [0, "0"];
      },
    },
    highMin: {
      type: [Number, String],
      default() {
        return [0, "0"];
      },
    },
    lowMax: {
      type: [Number, String],
      default() {
        return [0, "0"];
      },
    },
    lowMin: {
      type: [Number, String],
      default() {
        return [0, "0"];
      },
    },
    socArr: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    xData() {
      this.drawWeatherLine();
    },
    totalBatteryVoltage() {
      this.drawWeatherLine();
    },
    loadActivePower() {
      this.drawWeatherLine();
    },
    socArr() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    let that = this;
    window.addEventListener("resize", function () {
      console.log("resize");
      // that.drawWeatherLine();
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    let that = this;
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      // debugger;
      let highMax = this.highMax;
      let highMin = this.highMin;
      let lowMax = this.lowMax;
      let lowMin = this.lowMin;
      var option = {
        grid: {
          left: "3%",
          right: "7%",
          bottom: "7%",
          containLabel: true,
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          // backgroundColor: "rgba(255,255,255,0.8)",
          formatter: function (params) {
            console.log(params);
            return (
              params.value[2] +
              " :<br/>" +
              params.value[0] +
              "V " +
              params.value[1] +
              "V "
            );
          },
          backgroundColor: "rgba(0,0,0,.5)",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "rgba(0,0,0,.5)",
            },
          },
        },
        toolbox: {
          feature: {
            dataZoom: {},
            brush: {
              type: ["rect", "polygon", "clear"],
            },
          },
        },
        brush: {},
        // legend: {
        //   data: ["FeVol.", "Vol."],
        //   left: "center",
        //   bottom: 10,
        // },
        xAxis: [
          {
            type: "value",
            min: (highMin - 0.2).toFixed(2),
            max: (parseFloat(highMax) + 0.2).toFixed(2),
            // min: 2.8,
            // max: 3.8,

            axisLabel: {
              formatter: "{value} V",
              textStyle: {
                color: "rgba(255,255,255,.89)",
              },
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,.25)", // X轴线的颜色
                width: 1, // x轴线的宽度
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: (lowMin - 0.2).toFixed(2),
            max: (parseFloat(lowMin) + 0.3).toFixed(2),
            // min: 2.5,
            // max: 3.5,

            axisLabel: {
              formatter: "{value} V",
              textStyle: {
                color: "rgba(255,255,255,.89)",
              },
            },

            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(255,255,255,.25)", // X轴线的颜色
                width: 1, // x轴线的宽度
              },
            },
          },
        ],
        series: [
          {
            name: "Vol.",
            type: "scatter",
            // type: "effectScatter",
            // 'render'绘制完成后显示特效, 'emphasis' 高亮（hover）的时候显示特效。
            // rippleEffect: {
            //   // 添加涟漪动画效果
            //   brushType: "stroke",
            // },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: function (params) {
                // 判断散点是否在范围内
                var x = params.data[0];
                var y = params.data[1];
                console.log();
                if (
                  x >= highMin &&
                  x <= highMax &&
                  y >= lowMin &&
                  y <= lowMax
                ) {
                  return "#00cab7"; // 在范围内的散点为绿色
                } else if (x > highMax && y < lowMin) {
                  console.log(x, highMax, y, lowMin);
                  return "red";
                } else {
                  // console.log(x, highMax, y, lowMax);
                  return "yellow"; // 不在范围内的散点为红色
                }
              },
            },
            data: this.xData,
            markArea: {
              silent: true,
              itemStyle: {
                color: "transparent",
                borderWidth: 1,
                borderType: "dashed",
              },
              data: [
                [
                  {
                    name: "",
                    label: {
                      show: true,
                      position: "insideBottom", // markArea中文字（name）位置
                      offset: [0, -40], // markArea中文字（name）显示在位置基础上x、y轴偏移
                      color: "#ff0000", // markArea中文字（name）颜色
                    },
                    itemStyle: {
                      color: "rgba(50, 163, 182, 0.16)",
                      borderColor: "#00A5C4",
                      borderWidth: 2,
                      borderType: "dotted",
                    },
                    coord: [this.highMin, this.lowMin],
                  },
                  {
                    label: {
                      show: true,
                      position: "insideBottom", // markArea中文字（name）位置
                      offset: [0, -40], // markArea中文字（name）显示在位置基础上x、y轴偏移
                      color: "#ff0000", // markArea中文字（name）颜色
                    },
                    itemStyle: {
                      color: "rgba(50, 163, 182, 0.16)",
                      borderColor: "#00A5C4",
                      borderWidth: 2,
                      borderType: "dotted",
                    },
                    coord: [this.highMax, this.lowMax],
                  },
                ],
              ],
            },

            // markPoint: {
            //   data: [
            //     { type: "max", name: "Max" },
            //     { type: "min", name: "Min" },
            //   ],
            // },
            // markLine: {
            //   lineStyle: {
            //     type: "solid",
            //   },
            //   data: [{ type: "average", name: "Average" }, { xAxis: 170 }],
            // },
          },
          {
            type: "line",
            markLine: {
              data: [
                { yAxis: lowMax }, // 标记水平线，通过 yAxis 属性设置位置
              ],
              silent: true,
              itemStyle: {
                color: "#FE8800", // 设置标记线的线条颜色
              },
              label: {
                color: "#fff", // 设置标记线的字体颜色
              },
              symbol: "none",
            },
          },
          {
            type: "line",
            markLine: {
              data: [
                { yAxis: lowMin }, // 标记水平线，通过 yAxis 属性设置位置
              ],
              silent: true,
              symbol: "none",
              itemStyle: {
                color: "#FE8800", // 设置标记线的线条颜色
              },
              label: {
                color: "#fff", // 设置标记线的字体颜色
              },
            },
          },
          {
            type: "line",
            markLine: {
              data: [
                { xAxis: highMax }, // 标记水平线，通过 yAxis 属性设置位置
              ],
              silent: true,
              symbol: "none",
              itemStyle: {
                color: "#FE8800", // 设置标记线的线条颜色
              },
              label: {
                color: "#fff", // 设置标记线的字体颜色
              },
            },
          },
          {
            type: "line",
            markLine: {
              data: [
                { xAxis: highMin }, // 标记水平线，通过 yAxis 属性设置位置
              ],
              symbol: "none",
              silent: true,
              itemStyle: {
                color: "#FE8800", // 设置标记线的线条颜色
              },
              label: {
                color: "#fff", // 设置标记线的字体颜色
              },
            },
          },
          // {
          //   type: "line",
          //   markLine: {
          //     data: {
          //       yAxis: 0.5,
          //       name: "Line",
          //     },
          //   },
          // },
        ],
      };

      // 绘制图表
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.line-container {
    height: 100%;
    width: 100%;
    display: flex
    justify-content: center
}
</style>
