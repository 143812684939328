<template>
  <div>
    <div class="siteName">
      {{ deviceInfo.siteName + "EnerArk" + deviceInfo.slaveId }}
    </div>
    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane>
        <span slot="label"
          ><i class="iconfont icon-siteList"></i> 设备信息</span
        >
        <device-detail :isFresh="isFresh"></device-detail>
      </el-tab-pane>
      <el-tab-pane label="运维巡检">
        <span slot="label"
          ><i class="iconfont icon-wangluotuopu"></i> 运维巡检</span
        >
        <inspection :isFresh="isFresh"></inspection>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import deviceDetail from "./deviceDetail.vue";
import Inspection from "./inspection.vue";
export default {
  components: { deviceDetail, Inspection },
  data() {
    return {
      isFresh: 0,
      deviceInfo: {
        siteId: "",
        slaveId: "",
        siteName: "",
        // deviceName:""
      },
    };
  },
  mounted() {
    this.deviceInfo.siteId = this.$route.query.siteId;
    this.deviceInfo.slaveId = this.$route.query.slaveId;
    this.deviceInfo.siteName = this.$route.query.siteName;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index, event, "kjhdsuygsygsydgddsy");
      this.isFresh = tab.index + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.siteName {
  position: absolute;
  height: 44px;
  z-index: 2000;
  right: 0;
  line-height: 44px;
  margin-right: 20px;
}
</style>
