<template>
  <div class="flex items-middle flex-center" style="height: 100%; width: 100%">
    <div class="flex items-middle space-around" style="width: 100%">
      <div
        class="img-box flex flex-col flex-center items-middle"
        style="width: 40%"
      >
        <div class="round-box">
          <div class="round">
            <img src="@/assets/imgs/u8435.png" />
          </div>
          <div class="content">
            <img :src="info.imgSrc" style="width: 50px" />
          </div>
        </div>
        <div class="bg-box1">
          <img src="@/assets/imgs/u8431.png" />
        </div>
        <div class="name">{{ info.name }}</div>
      </div>
      <div class="info-box">
        <div class="state">
          <div class="state-data flex">
            <div class="point" :class="pointColor"></div>
            <div class="state-value">{{ info.key[0].value }}</div>
          </div>
        </div>
        <div class="info">
          <el-descriptions :column="1">
            <template v-for="(v, i) in info.key">
              <el-descriptions-item
                :label="v.name"
                :key="i"
                v-if="v.unit != ''"
              >
                <span class="value">{{ v.value | num }}</span>
                <span class="unit">{{ v.unit }}</span></el-descriptions-item
              >
            </template>
          </el-descriptions>
        </div>
        <!-- <list-info
          v-for="(v, i) in info.key"
          :key="i"
          :label="v.name"
          :unit="v.unit"
          :value="v.value"
        ></list-info> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    pointColor() {
      return this.info.key[0].value == "运行"
        ? "bg-green"
        : this.info.key[0].value == "故障"
        ? "bg-red"
        : "bg-purple";
    },
  },
};
</script>

<style lang="scss" scoped>
.point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.state-data {
  margin: 15px 0;
  color: #bccbd5;
}
.state-value {
  padding-left: 10px;
}
.round-box {
  width: 80px;
  height: 80px;
  position: relative;
}
.round {
  animation: rotate 2s linear infinite;
}
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 15px;
  left: 15px;
}
.bg-box1 {
  margin-top: -50px;
}
.name {
  margin-top: 20px;
}
.info-box {
  width: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/deep/ .el-descriptions-item__label:not(.is-bordered-label) {
  color: #bccbd5;
}
/deep/
  .el-descriptions__body
  .el-descriptions__table
  .el-descriptions-item__cell {
  color: #bccbd5;
}
</style>
