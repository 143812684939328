<template>
  <div class="line-container">
    <div :id="lineDiv" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
// 引入折现图组件
var echarts = require("echarts");
window.$echarts = echarts;
require("echarts/lib/chart/line");
export default {
  name: "BLine",
  props: {
    lineDiv: {
      type: [Number, String],
      default() {
        return `ring${(((1 + Math.random()) * 0x10000) | 0)
          .toString(16)
          .substring(1)}`;
      },
    },
    xData: {
      type: Array,
      default() {
        return [];
      },
    },
    gridActivePower: {
      type: Array,
      default() {
        return [];
      },
    },
    loadActivePower: {
      type: Array,
      default() {
        return [];
      },
    },
    socArr: {
      type: Array,
      default() {
        return [];
      },
    },
    title1: {
      type: String,
      default() {
        return "";
      },
    },
    title2: {
      type: String,
      default() {
        return "";
      },
    },
    title3: {
      type: String,
      default() {
        return "";
      },
    },
    data3YAxisIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    y1name: {
      type: String,
      default() {
        return "";
      },
    },
    isFresh: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    xData() {
      this.drawWeatherLine();
    },
    gridActivePower() {
      this.drawWeatherLine();
    },
    loadActivePower() {
      this.drawWeatherLine();
    },
    socArr() {
      this.drawWeatherLine();
    },
    title1() {
      this.drawWeatherLine();
    },
    title3() {
      this.drawWeatherLine();
    },
    title4() {
      this.drawWeatherLine();
    },
    data3YAxisIndex() {
      this.drawWeatherLine();
    },
    y1name() {
      this.drawWeatherLine();
    },
    isFresh() {
      this.drawWeatherLine();
    },
  },
  mounted() {
    this.drawWeatherLine();
    let that = this;
    window.addEventListener("resize", function () {
      console.log("resize");
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  beforeDestroy() {
    let that = this;
    window.removeEventListener("resize", () => {
      window.$echarts.init(document.getElementById(that.lineDiv)).resize();
    });
  },
  methods: {
    drawWeatherLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = window.$echarts.init(document.getElementById(this.lineDiv));
      myChart.setOption({
        color: ["#00D19E", "#ffc328", "#d04c79"],
        legend: {
          itemHeight: 9, //改变圆圈大小
          data: [
            {
              name: this.title1,
              icon: "circle",
            },
            {
              name: this.title2,
              icon: "circle",
            },
            {
              name: this.title3,
              icon: "circle",
            },
          ],
          right: 10,
          top: 0,
          x: "center",
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: 50,
          right: 50,
          bottom: 50,
          top: 50,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderWidth: 0, // 提示框浮层的边框宽。
          // padding: 5, // 提示框浮层内边距，
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#fff",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: 12,
          },
        },
        xAxis: [
          {
            type: "category",
            // 横向网格
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            // x轴字体设置
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#aaaeb9",
              },
              formatter: function (params) {
                let x =
                  params.substring(0, 10) +
                  "\n" +
                  params.substring(11, params.length - 3);
                return x;
                // console.log(params, "params", x);
              },
            },
            data: this.xData,
          },
        ],

        yAxis: [
          {
            name: "kW",
            type: "value",

            splitLine: {
              show: false,
              lineStyle: {
                type: "dotted",
              },
            },
            // y轴字体设置
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
          },
          {
            name: "%",
            type: "value",
            splitLine: {
              show: false,
            },
            // y轴字体设置
            axisLabel: {
              show: true,
              // formatter: '{value} %',
              textStyle: {
                fontSize: 12,
                color: "#fff",
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            name: this.title1,
            data: this.gridActivePower,
            connectNulls: true,
            symbol: "circle", //拐点样式
            symbolSize: 5, //拐点大小
            itemStyle: {
              color: "#6A5ACD",
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 1,
                  type: "solid",
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#00d19e",
                    },
                    {
                      offset: 1,
                      color: "#00d19e",
                    },
                  ]), //线条渐变色
                },
              },
            },
          },
          {
            type: "line",
            name: this.title2,
            data: this.loadActivePower,
            connectNulls: true,
            symbol: "circle", //拐点样式
            symbolSize: 5, //拐点大小
            itemStyle: {
              color: "#6A5ACD",
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: "solid",
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#ffc328",
                    },
                    {
                      offset: 1,
                      color: "#ffc328",
                    },
                  ]), //线条渐变色
                },
              },
            },
          },
          {
            type: "line",
            name: this.title3,
            data: this.socArr,
            yAxisIndex: this.data3YAxisIndex,
            connectNulls: true,
            symbol: "circle", //拐点样式
            symbolSize: 5, //拐点大小
            itemStyle: {
              color: "#6A5ACD",
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: "solid",
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#d04c79",
                    },
                    {
                      offset: 1,
                      color: "#d04c79",
                    },
                  ]), //线条渐变色
                },
              },
            },
          },
        ],
      });
      myChart.resize();
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.line-container {
    height: 100%;
    width: 100%;
}
</style>
